import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-footer/page-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-header/page-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/page-navbar/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/base/base-layout.tsx");
